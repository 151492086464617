import React, { useState } from 'react';
import Accordion from '../components/accordion';
import GoBackLink from '../components/go-back-link';
import Meta from '../components/meta';
import ModulesController from '../components/modules-controller';
import SearchLibrary from '../components/search-library';
import { useContent } from '../hooks/use-content';
import { PageData } from '../types/global.types';
import { IFAQPage } from '../types/heartcore.types';

const FAQPage = ({pageContext}:PageData<IFAQPage>) => {
	const { meta, parent, modules, headline, bodyText, questions, searchData } = useContent(pageContext);
	const [filteredQuestions, setFilteredQuestions] = useState(null);

	const searchHandler = (data) => {
		if(!questions) { return; }

		if(!data) {
			setFilteredQuestions(questions);
			return;
		}
		const filteredSearchData = searchData.searchContent.filter(sc => data.includes(sc.id));
		const headlinesArray = filteredSearchData.map(fsd => fsd.headline);
		const newFilteredQuestions = [];
		questions.forEach(q => {
			const filteredEntries = q.entries.filter(e => headlinesArray.includes(e.headline));
			if (filteredEntries.length > 0) {
				const section = newFilteredQuestions.find(fq => fq.headline === q.headline);
				if (section) {
					section.entries = filteredEntries;
				} else {
					newFilteredQuestions.push({headline: q.headline, entries: filteredEntries});
				}
			}
		});
		setFilteredQuestions(newFilteredQuestions);
	};

	return (
		<>
			<Meta {...meta} />
			<div className='faq-page'>
				<div className='box-wide'>
					<GoBackLink parent={parent}></GoBackLink>
					<div className='faq-page__content'>
						<h1 className='faq-page__content__headline'>{headline}</h1>
						<p>{bodyText}</p>
					</div>
					{searchData && <SearchLibrary pageSize={1000} searchData={searchData} callback={searchHandler} >
						{filteredQuestions?.map(question => 
							<Accordion key={question.headline} headline={question.headline} entries={question.entries} disableBoxWide={true} disableBottomMargin={false} disableTopMargin={false} contentTypeAlias={null} />,
						)}
					</SearchLibrary>}
				</div>
				<ModulesController modules={modules} />
			</div>
		</>
	);
};

export default FAQPage;
